.content p {
  margin-top: 10px;
  font-size: var(--font-size-custom-17px);
}

.content h2 {
  margin-top: 10px;
  margin-left: 10px;
  font-size: var(--font-size-custom-17px);
  font-style: italic;
}

.content h2::after {
  content: '- ';
}

.content p a {
  text-decoration: underline;
  color:dodgerblue;
  cursor: pointer;
}

.content form button:disabled{
  text-decoration: line-through;
}

.content form button:disabled:hover{
  color: dimgray;
}

