.diamond-template {
  display: flex;
  padding: 32px 0;
  margin-top: 50px;
  margin-left: -1000px;
  margin-right: -1000px;
  transform: rotate(4.12deg);
}
.photo {
  transition: transform 1s ease-in-out;
}

.photo-active {
  animation: scalePhoto 2s infinite;
}

@keyframes scalePhoto {
  0%, 100% { transform: scale(1.0); }
  50% { transform: scale(1.1); }
}

.photo-prev {
  transform: translateX(-100%);
}

.photo-next {
  transform: translateX(100%);
}