.header {
    font-weight: 900;
    margin-bottom: 30px;
}

.contentParagraf p {
    margin-top: 20px;
    margin-left: 20px;
    font-size: var(--font-size-custom-16px);
}

.contentParagraf h1 {
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    font-size: var(--font-size-xl);
}

.contentParagraf h2 {
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    margin-left: 10px;
    font-size: var(--font-size-custom-16px);
}

.contentParagraf h3 {
    margin-top: 20px;
    font-weight: 700;
    font-size: var(--font-size-custom-16px);
}
.contentParagraf a {
    text-decoration: underline;
    color:dodgerblue;
    cursor: pointer;
}