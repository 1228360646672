@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
    --font-size-xl: 20px;
    --font-size-4xl: 36px;
    --font-size-8xl: 96px;
    --font-size-custom-12px: 30px;
    --font-size-custom-14px: 14px;
    --font-size-custom-16px: 16px;
    --font-size-custom-17px: 17px;
    --font-size-custom-19px: 19px;
    --font-size-custom-28px: 28px;
    --font-size-custom-30px: 30px;
    --font-size-custom-32px: 32px;
    --font-size-custom-40px: 40px;
    --font-size-custom-extended-40px: 40px;
    --font-size-custom-extended-16px: 16px;
}

.text-custom-xl {
    font-size: var(--font-size-xl);
}

.text-custom-4xl {
    font-size: var(--font-size-4xl);
}

.text-custom-8xl {
    font-size: var(--font-size-8xl);
}

.text-custom-12px {
    font-size: var(--font-size-custom-12px);
}

.text-custom-14px {
    font-size: var(--font-size-custom-14px);
}

.text-custom-16px {
    font-size: var(--font-size-custom-16px);
}

.text-custom-17px {
    font-size: var(--font-size-custom-17px);
}

.text-custom-19px {
    font-size: var(--font-size-custom-19px);
}

.text-custom-28px {
    font-size: var(--font-size-custom-28px);
}

.text-custom-30px {
    font-size: var(--font-size-custom-30px);
}

.text-custom-32px {
    font-size: var(--font-size-custom-32px);
}

.text-custom-40px {
    font-size: var(--font-size-custom-40px);
}

.text-custom-extended-40px {
    font-size: var(--font-size-custom-extended-40px);
}

.text-custom-extended-16px {
    font-size: var(--font-size-custom-extended-16px);
}