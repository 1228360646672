@media (min-width: 2560px) {
    .scaleContainer {
        transform: scale(1.4);
        transform-origin: top center;
    }
}

@media (min-width: 3840px) {
    .scaleContainer {
        transform: scale(1.7);
        transform-origin: top;
        transform-origin: top center;
    }
}

.footerContainer {
    display: flex;
    margin-left: -1000px;
    margin-right: -1000px;
    transform: rotate(0);
}